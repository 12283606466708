<template>

  <div class="service__request sr__listPage">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Stock List
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <!-- stock in button -->
        <b-col
          class="content-header-right mb-2 ml-auto"
          cols="12"
          md="12"
        >
          <div style="display: flex; justify-content: space-between;">
            <b-button
              v-if="specialCanViewThisAction('stock-in')"
              variant="success"
              style="height: 40px;"
              @click="emptyStockInPage()"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Stock In</span>
            </b-button>
          </div>
        </b-col>
        <!-- ./ stock in button -->
        <!-- stock status -->
        <b-form-radio-group
          id="stock-status"
          v-model="status"
          button-variant="outline-primary"
          buttons
          class="spaceBetweenRadio radio-group-select"
        >
          <b-form-radio
            value="low stock"
            class="status-radio-btn"
          >
            <span class="d-sm-inline">Low Stock <span class="rounded-number">{{ lowStockCount }}</span></span>
          </b-form-radio>
          <b-form-radio
            value="out of stock"
            class="status-radio-btn"
          >
            <span class="d-sm-inline">Out of Stock <span class="rounded-number">{{ outOfStockCount }}</span></span>
          </b-form-radio>
          <b-form-radio
            value="all"
            class="status-radio-btn"
          >
            <span class="d-sm-inline">All Stock <span class="rounded-number">{{ allStockCount }}</span></span>
          </b-form-radio>
        </b-form-radio-group>
        <!-- ./ stock status  -->
        <!-- stock-list table  -->
        <div class="mt-2">
          <!-- Table Container Card -->
          <b-card
            no-body
            class="mb-0"
          >
            <div class="m-2">

              <!-- Table Top -->
              <b-row>
                <b-col
                  md="2"
                />
                <b-col
                  v-if="isMobile()"
                  cols="10"
                  md="5"
                  class="d-flex align-items-center justify-content-space_between mb-1 mb-md-0"
                >
                  <div class="bulk_selection d-flex">
                    <div class="action-left pt-50">
                      <b-form-checkbox
                        :checked="selectAllStockItemCheckbox"
                        :indeterminate="isSelectAllStockItemCheckboxIndeterminate"
                        @change="selectAllCheckboxUpdate"
                      />
                    </div>
                    <b-dropdown
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="mx-1"
                      right
                      text="Bulk Actions"
                      variant="primary"
                      :disabled="!selectedStockItems.length"
                    >
                      <b-dropdown-item
                        v-if="specialCanViewThisAction('stock-in')"
                        @click="selectedStockInPage()"
                      >
                        Stock In
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="specialCanViewThisAction('stock-out')"
                        @click="selectedStockOutPage()"
                      >
                        Stock Out
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="specialCanViewThisAction('stock-transfer')"
                        @click="selectedStockTransferPage()"
                      >
                        Transfer Stock
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="selectedStockPRPage()"
                      >
                        Create Purchase Request
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <span class="text-muted">Showing <br>{{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
                </b-col>
                <!-- Per Page -->
                <b-col
                  v-else
                  cols="10"
                  md="5"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                  <div class="action-left pt-50">
                    <b-form-checkbox
                      :checked="selectAllStockItemCheckbox"
                      :indeterminate="isSelectAllStockItemCheckboxIndeterminate"
                      @change="selectAllCheckboxUpdate"
                    />
                  </div>
                  <b-dropdown
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mx-1"
                    right
                    text="Bulk Actions"
                    variant="primary"
                    :disabled="!selectedStockItems.length"
                  >
                    <b-dropdown-item
                      v-if="specialCanViewThisAction('stock-in')"
                      @click="selectedStockInPage()"
                    >
                      Stock In
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="specialCanViewThisAction('stock-out')"
                      @click="selectedStockOutPage()"
                    >
                      Stock Out
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="specialCanViewThisAction('stock-transfer')"
                      @click="selectedStockTransferPage()"
                    >
                      Transfer Stock
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="selectedStockPRPage()"
                    >
                      Create Purchase Request
                    </b-dropdown-item>
                  </b-dropdown>
                  <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
                </b-col>
                <!-- Search -->
                <b-col
                  v-if="$route.name == 'inventory-stock-list'"
                  cols="10"
                  md="5"
                >
                  <div class="d-flex align-items-center justify-content-end">
                    <a
                      href="javascript:void(0)"
                      class="nav-link nav-link-search"
                      @click="showSearchBar = !showSearchBar"
                    >
                      <feather-icon
                        icon="SearchIcon"
                        size="21"
                        class="mr-50"
                      />
                      <span class="align-middle">Search</span>
                    </a>
                    <b-input-group
                      v-if="showSearchBar"
                      class="input-group-merge mr-50"
                    >
                      <b-form-input
                        v-model="searchQuery"
                        placeholder="Search..."
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="searchQuery = ''; showSearchBar = !showSearchBar"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <b-dropdown
                      id="dropdown-Team-form"
                      ref="filter_dropdown_stock_list"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="dropdown-modal"
                      right
                      variant="link"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="FilterIcon"
                          size="21"
                          class="align-middle mr-50"
                        />
                        <span class="mr-1">Filters</span>
                      </template>
                      <stock-list-filter
                        :category.sync="categoryFilter"
                        :category-options="categoryOptions"
                        @emitHideFilter="hideFilter"
                        @emitShowFilter="showFilter"
                      />
                    </b-dropdown>
                  </div>
                </b-col>
              </b-row>
              <div v-if="categoryFilter.length">
                <hr class="dividerHR filter-divider">
                <b-row>

                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="12"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <ul
                      class="list-inline d-inline-block mb-1"
                    >
                      <li
                        v-for="category in categoryFilter"
                        :key="category"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          class="mb-50"
                          variant="danger"
                          @remove="removeFromCategoryFilter(category)"
                        >
                          <span class="text-capitalize">{{ resolveCategoryName(category) }}</span>
                        </b-form-tag>
                      </li>
                      <li
                        class="list-inline-item"
                      >
                        <span
                          class="text-danger clear-filter-text mb-50"
                          @click="clearAllFilters"
                        >
                          Clear Filters
                        </span>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
              </div>
            </div>

            <b-row class="no-gutters v-sticky-sidebar-container">
              <b-col
                sm="12"
                md="2"
                lg="2"
                style="box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);"
              >
                <vue-sticky-sidebar
                  class="sidebar"
                  :top-spacing="topSpacing"
                  container-selector=".v-sticky-sidebar-container"
                  inner-wrapper-selector=".sidebar__inner"
                >
                  <div
                    v-if="isMobile()"
                    class="p-2"
                  >
                    <b-form-group
                      label="Store"
                      label-for="store-dropdown"
                    >
                      <v-select
                        id="store-dropdown"
                        v-model="storeVal"
                        label="name"
                        :options="allActiveStores"
                        :reduce="store => store._id"
                        :clearable="false"
                      />
                    </b-form-group>
                  </div>
                  <div
                    v-else
                    class="store-wrapper"
                  >
                    <b-form-radio-group
                      id="selectedStore"
                      v-model="storeVal"
                      button-variant="flat-primary"
                      buttons
                      stacked
                    >
                      <b-form-radio
                        v-for="(storeValue, storeIndex) in allActiveStores"
                        :id="'store-radio'+ storeIndex"
                        :key="storeIndex"
                        class="rounded-0"
                        :value="storeValue._id"
                      >
                        <div class="single-store">
                          <div class="content-wrapper">
                            <h4 class="store-name">
                              {{ storeValue.name }}
                            </h4>
                            <p class="company-entity">
                              {{ storeValue.department.name }}
                            </p>
                          </div>
                        </div>
                      </b-form-radio>
                    </b-form-radio-group>
                  </div>
                </vue-sticky-sidebar>
              </b-col>
              <b-col
                sm="12"
                md="10"
                lg="10"
              >
                <b-table
                  ref="refStockItemsTable"
                  :items="fetchStockLists"
                  responsive
                  class="position-relative min-height-table"
                  :fields="tableColumns"
                  primary-key="_id"
                  :sort-by.sync="sortBy"
                  show-empty
                  no-sort-reset
                  empty-text="No matching records found"
                  :sort-desc.sync="isSortDirDesc"
                  :tbody-tr-class="rowClass"
                >
                  <!-- Column: Item Details -->
                  <template #cell(itemName)="data">
                    <b-form-checkbox
                      :checked="selectedStockItems.includes(data.item.item)"
                      @change="toggleSelectedStockItems(data.item.item)"
                      @click.native.stop
                    >
                      <div class="d-flex">
                        <b-img
                          class="mb-1 mb-sm-0 user__avatar img-preview-block"
                          :src="data.item.image ? data.item.image : defaultImage"
                        />
                        <div
                          class="ml-1"
                        >
                          <span class="text-bold-black">{{ data.item.itemName || '-' }}</span>
                          <br>
                          <span class="text-muted">{{ data.item.sku || '-' }}</span>
                        </div>
                      </div>
                    </b-form-checkbox>
                  </template>
                  <!-- Column: Category -->
                  <template #cell(categoryTitle)="data">
                    <div
                      class=""
                    >
                      <span class="">{{ data.item.categoryTitle || '-' }}</span>
                    </div>
                  </template>
                  <!-- Column: brand -->
                  <template #cell(brand)="data">
                    <div
                      class=""
                    >
                      <span class="">{{ data.item.brand || '-' }}</span>
                    </div>
                  </template>
                  <!-- Column: Status -->
                  <template #cell(status)="data">
                    <b-badge
                      :variant="`light-${resolveStockStatusVariant(data.item.status)}`"
                      class="text-capitalize"
                    >
                      {{ data.item.status }}
                    </b-badge>
                  </template>
                  <!-- Column: Actions -->
                  <template
                    v-if="canViewThisAction('update', 'InventoryItem') || canViewThisAction('delete', 'StockList') || specialCanViewThisAction('stock-in') || specialCanViewThisAction('stock-out') || specialCanViewThisAction('stock-transfer') || specialCanViewThisAction('stock-adjustment')"
                    #cell(actions)="data"
                  >
                    <div style="white-space: nowrap;">
                      <b-button
                        v-if="specialCanViewThisAction('stock-in')"
                        variant="outline-primary"
                        class="mr-2"
                        @click="singleStockInPage(data.item.item)"
                      >
                        <feather-icon
                          icon="DownloadIcon"
                          class="mr-50"
                        />
                        <span class="">In</span>
                      </b-button>
                      <b-button
                        v-if="specialCanViewThisAction('stock-out')"
                        variant="outline-primary"
                        @click="singleStockOutPage(data.item.item)"
                      >
                        <feather-icon
                          icon="UploadIcon"
                          class="mr-50"
                        />
                        <span class="">Out</span>
                      </b-button>
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                      >

                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>

                        <b-dropdown-item
                          v-if="specialCanViewThisAction('stock-adjustment')"
                          @click="singleStockAdjustmentPage(data.item.item)"
                        >
                          <feather-icon icon="EditIcon" />
                          <span class="align-middle ml-50">Adjust Stocks</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="specialCanViewThisAction('stock-transfer')"
                          @click="singleStockTransferPage(data.item.item)"
                        >
                          <feather-icon icon="EyeIcon" />
                          <span class="align-middle ml-50">Transfer Stocks</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="canViewThisAction('update', 'InventoryItem')"
                          :to="{ name: 'inventory-inventory-items-edit', params: { id: data.item.item } }"
                        >
                          <span class="align-middle ml-50">Edit on Master List</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="canViewThisAction('delete', 'StockList')"
                          @click="removeItemFromStore(data.item.item, data.item.store, data.item.stock)"
                        >
                          <span class="align-middle ml-50">Remove from store</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="createPRForSingleItem(data.item.item)"
                        >
                          <span class="align-middle ml-50">Create Purchase Request</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </template>

                </b-table>
              </b-col>
            </b-row>
          </b-card>

          <div
            v-if="totalStock > perPage"
            class="mx-2 mb-2"
          >
            <b-row>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center pagination-content"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalStock"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  align="center"
                  prev-text="Prev"
                  next-text="Next"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                    <span>Prev</span>
                  </template>
                  <template #next-text>
                    <span>Next</span>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <!-- ./ stock-list table  -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BButton, BCol, BFormRadioGroup, BFormRadio, BCard, BFormGroup, BFormInput, BTable, BFormCheckbox, BInputGroup, BInputGroupAppend,
  BDropdown, BDropdownItem, BPagination, BImg, BFormTag, BBadge,
} from 'bootstrap-vue'
import vueStickySidebar from 'vue-sticky-sidebar'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref, onUnmounted, computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import store from '@/store'

// eslint-disable-next-line import/no-cycle
import useAllStockListList from './useStockLists'
import StockListStoreModule from './stockListsStoreModule'
import StockListFilter from './StockListFilter.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormRadioGroup,
    BFormRadio,
    BCard,
    BImg,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormTag,
    BBadge,
    BFormGroup,

    vSelect,
    'vue-sticky-sidebar': vueStickySidebar,
    StockListFilter,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: store.state.auth.userData,
      topSpacing: 135,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      allActiveStores: [],
      selectedStore: '',
      categoryOptions: [],
      items: [],
      vStoreSelect: '',
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,

      required,
    }
  },
  computed: {
    totalQuantity() {
      return this.quantity.reduce((a, b) => Number(a) + Number(b), 0)
    },
  },
  created() {
    this.$http.get('inventory/store/respond-with/name-and-id', { params: { department: true } })
      .then(response => {
        this.allActiveStores = response.data.stores
        if (this.allActiveStores.length) {
          this.storeVal = this.$route.query.store || this.allActiveStores[0]._id
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.$http.get('inventory/category/respond-with/name-and-id')
      .then(response => {
        this.categoryOptions = response.data.categories ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    window.addEventListener('beforeunload', () => {
      localStorage.removeItem('storeOperation')
    })
  },
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem('storeOperation')
    next()
  },
  methods: {
    rowClass(item) {
      if (item) {
        const items = JSON.parse(localStorage.getItem('items'))
        const operation = localStorage.getItem('storeOperation')
        if (operation) {
          if (operation === 'stock-adjustment') {
            const stockAdjustmentFound = items.find(obj => obj.item === item.item && obj.store === this.storeVal)
            if (stockAdjustmentFound) {
              if (stockAdjustmentFound.stock > +stockAdjustmentFound.quantity) {
                return 'table-danger-custom'
              }
              return 'table-success-custom'
            }
          } else if (operation === 'stock-transfer') {
            const fromStore = localStorage.getItem('fromStore')
            const toStore = localStorage.getItem('toStore')
            const stockTransferInFound = items.find(obj => obj.item === item.item && toStore === this.storeVal)
            if (stockTransferInFound) {
              return 'table-success-custom'
            }
            const stockTransferOutFound = items.find(obj => obj.item === item.item && fromStore === this.storeVal)
            if (stockTransferOutFound) {
              return 'table-danger-custom'
            }
          } else {
            const found = items.find(obj => obj.item === item.item && obj.stores.find(o => o.store === this.storeVal))
            if (found) {
              if (operation === 'stock-out') {
                return 'table-danger-custom'
              }
              if (operation === 'stock-in') {
                return 'table-success-custom'
              }
            }
          }
        }
      }
      return ''
    },
    hideFilter() {
      this.$refs.filter_dropdown_stock_list.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_stock_list.show(true)
    },
    removeFromCategoryFilter(category) {
      const index = this.categoryFilter.indexOf(category)
      if (index > -1) {
        this.categoryFilter.splice(index, 1)
      }
    },
    clearAllFilters() {
      this.categoryFilter = []
    },
    specialCanViewThisAction(option) {
      if (option && this.storeVal && this.allActiveStores.length) {
        const foundStore = this.allActiveStores.find(o => o._id === this.storeVal)
        const filteredArray = foundStore.roles.filter(o1 => this.user.roles.some(o2 => o1._id === o2._id))
        const foundPermission = filteredArray.filter(o => o.permissions.find(i => i.action === option))
        if (foundPermission.length) {
          return true
        }
      }
      return false
    },
    resolveCategoryName(id) {
      const category = this.categoryOptions.find(o => o._id === id)
      if (category) {
        return category.name || ''
      }
      return ''
    },
    emptyStockInPage() {
      localStorage.removeItem('items')
      localStorage.setItem('storeVal', this.storeVal)
      this.$router.push({ name: 'inventory-stock-in' })
    },
    singleStockInPage(item) {
      this.items = []

      const stockSingleItem = this.stockListData.find(stockObj => stockObj.item === item)
      this.items.push(stockSingleItem)
      localStorage.setItem('storeVal', this.storeVal)
      localStorage.setItem('items', JSON.stringify(this.items))
      this.$router.push({ name: 'inventory-stock-in' })
    },
    selectedStockInPage() {
      this.items = []
      this.selectedStockItems.forEach(item => {
        const stockSingleItem = this.stockListData.find(stockObj => stockObj.item === item)
        this.items.push(stockSingleItem)
      })
      if (this.items.length) {
        localStorage.setItem('storeVal', this.storeVal)
        localStorage.setItem('items', JSON.stringify(this.items))
        this.$router.push({ name: 'inventory-stock-in' })
      }
    },
    singleStockOutPage(item) {
      this.items = []
      const stockSingleItem = this.stockListData.find(stockObj => stockObj.item === item)
      this.items.push(stockSingleItem)
      localStorage.setItem('storeVal', this.storeVal)
      localStorage.setItem('items', JSON.stringify(this.items))
      this.$router.push({ name: 'inventory-stock-out' })
    },
    selectedStockOutPage() {
      this.items = []
      this.selectedStockItems.forEach(item => {
        const stockSingleItem = this.stockListData.find(stockObj => stockObj.item === item)
        this.items.push(stockSingleItem)
      })
      if (this.items.length) {
        localStorage.setItem('storeVal', this.storeVal)
        localStorage.setItem('items', JSON.stringify(this.items))
        this.$router.push({ name: 'inventory-stock-out' })
      }
    },
    singleStockAdjustmentPage(item) {
      this.items = []
      const stockSingleItem = this.stockListData.find(stockObj => stockObj.item === item)
      this.items.push(stockSingleItem)
      localStorage.setItem('storeVal', this.storeVal)
      localStorage.setItem('items', JSON.stringify(this.items))
      this.$router.push({ name: 'inventory-stock-adjustment' })
    },
    singleStockTransferPage(item) {
      this.items = []
      const stockSingleItem = this.stockListData.find(stockObj => stockObj.item === item)
      this.items.push(stockSingleItem)
      localStorage.setItem('storeVal', this.storeVal)
      localStorage.setItem('items', JSON.stringify(this.items))
      this.$router.push({ name: 'inventory-stock-transfer' })
    },
    createPRForSingleItem(item) {
      this.items = []
      const stockSingleItem = this.stockListData.find(stockObj => stockObj.item === item)
      this.items.push(stockSingleItem)
      localStorage.setItem('prItems', JSON.stringify(this.items))
      this.$router.push({ name: 'purchasing-purchase-requests-create' })
    },
    selectedStockTransferPage() {
      this.items = []
      this.selectedStockItems.forEach(item => {
        const stockSingleItem = this.stockListData.find(stockObj => stockObj.item === item)
        this.items.push(stockSingleItem)
      })
      if (this.items.length) {
        localStorage.setItem('storeVal', this.storeVal)
        localStorage.setItem('items', JSON.stringify(this.items))
        this.$router.push({ name: 'inventory-stock-transfer' })
      }
    },
    selectedStockPRPage() {
      this.items = []
      this.selectedStockItems.forEach(item => {
        const stockSingleItem = this.stockListData.find(stockObj => stockObj.item === item)
        this.items.push(stockSingleItem)
      })
      if (this.items.length) {
        localStorage.setItem('prItems', JSON.stringify(this.items))
        this.$router.push({ name: 'purchasing-purchase-requests-create' })
      }
    },
    removeItemFromStore(itemId, storeId, stockQty) {
      if (stockQty > 0) {
        this.$swal({
          title: 'Warning!',
          text: 'Please stock out all quantities of this item before removing it from this store.',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          imageWidth: 80,
          imageHeight: 80,
          imageAlt: 'Custom Icon',
          showCancelButton: false,
          confirmButtonText: 'Okay',
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
          },
          buttonsStyling: false,
        })
      } else {
        this.$swal({
          title: 'Warning!',
          text: ' Are you sure you want to delete this item from this store?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Remove!',
          cancelButtonText: 'No, Cancel',
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-primary ml-1',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              const formData = new FormData()
              formData.append('item', itemId)
              formData.append('store', storeId)
              this.$http.post('inventory/stock/remove/item', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
              })
                .then(response => {
                  this.refetchData()
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
                .catch(error => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      }
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const STOCK_LIST_APP_STORE_MODULE_NAME = 'app-stock-list'

    // Register module
    if (!store.hasModule(STOCK_LIST_APP_STORE_MODULE_NAME)) store.registerModule(STOCK_LIST_APP_STORE_MODULE_NAME, StockListStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STOCK_LIST_APP_STORE_MODULE_NAME)) store.unregisterModule(STOCK_LIST_APP_STORE_MODULE_NAME)
    })

    const { canViewThisAction } = useAclUtils()

    const {
      fetchStockLists,
      tableColumns,
      perPage,
      currentPage,
      totalStock,
      stockListData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStockItemsTable,
      refetchData,
      currentDataLength,

      toDeletedID,
      deleteStockList,
      status,
      storeVal,
      allStockCount,
      outOfStockCount,
      lowStockCount,
      // UI
      resolveStockStatusVariant,

      // Extra Filter
      categoryFilter,
    } = useAllStockListList()

    const selectedStockItems = ref([])
    const toggleSelectedStockItems = itemId => {
      const inventoryItemIndex = selectedStockItems.value.indexOf(itemId)

      if (inventoryItemIndex === -1) selectedStockItems.value.push(itemId)
      else selectedStockItems.value.splice(inventoryItemIndex, 1)
    }
    const selectAllStockItemCheckbox = computed(() => stockListData.value.length && (stockListData.value.length === selectedStockItems.value.length))
    const isSelectAllStockItemCheckboxIndeterminate = computed(() => Boolean(selectedStockItems.value.length) && stockListData.value.length !== selectedStockItems.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedStockItems.value = val ? stockListData.value.map(sData => sData.item) : []
    }

    return {

      // Sidebar
      fetchStockLists,
      tableColumns,
      perPage,
      currentPage,
      totalStock,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStockItemsTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteStockList,
      stockListData,
      currentDataLength,

      // UI
      resolveStockStatusVariant,

      // Filter
      avatarText,
      canViewThisAction,

      status,
      storeVal,
      allStockCount,
      outOfStockCount,
      lowStockCount,

      // Extra Filters
      categoryFilter,

      selectAllStockItemCheckbox,
      isSelectAllStockItemCheckboxIndeterminate,
      selectedStockItems,
      toggleSelectedStockItems,
      selectAllCheckboxUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.filter-dropdown{
  width: 100%;
  margin-top: 16px;
}
.per-page-selector {
  width: 90px;
}
.modal .form-control {
    margin: 0;
}
.img-preview-block{
      box-sizing: border-box;
      width: 38px;
      height: 38px;
      border: 1px solid #D2DCEA;
      border-radius: 5px;
  }

@media (max-width: 767px) {
.StockLists_fields {
    padding: 10px 0 0 0;
  }

.new_design_requests {
    margin-top: 10px !important;
}

.modal .form-control {
    margin-top: 5px;
}

.filter-dropdown{
  width: 100%;
}
}

// start store section css
.store-wrapper {
   display: flex;
   flex-direction: column;
   border-top: 1px solid #e7edf5;
   max-height: calc(100vh - 125px);
   overflow-y: auto;
}
.single-store{
    width: 100%;
    padding: 8px;
}
.content-wrapper{
    justify-content: center;
    align-items: flex-start;
    gap: 3px;
    margin: 0;
    text-align: left;
}
.content-wrapper .store-name{
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #000000;
}
.content-wrapper .company-entity{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #333333;
    margin: 0;
}
// ./end store section css
  .status-radio-btn{
     background-color: #F5F8FB;
    padding: 7px 12px 7px 12px;
  }
  .status-radio-btn.btn.btn-outline-primary:hover{
     background-color: #F5F8FB;
  }
  .btn-outline-primary:not(:disabled):not(.disabled).active {
    background: #104D9D;
    color: #fff;
  }
  .radio-group-select span.rounded-number {
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    display: inline-block;
    font-weight: 600;
  }
  .rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
  }
</style>
