import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useAllInventoryItemList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refStockItemsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'itemName',
      label: 'item',
      tdClass: 'width-30',
      sortable: true,
    },
    {
      key: 'categoryTitle',
      label: 'category',
      sortable: true,
    },
    {
      key: 'brand',
      label: 'Brand',
      sortable: true,
    },
    {
      key: 'stock',
      label: 'Qty',
      sortable: true,
    },
    {
      key: 'status',
      label: 'status',
      sortable: true,
    },
    {
      key: 'actions',
    },
  ]
  const perPage = ref(route.query.perPage ?? 30)
  const totalStock = ref(0)
  const allStockCount = ref(0)
  const outOfStockCount = ref(0)
  const lowStockCount = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const status = ref(route.query.status || 'all')
  const storeVal = ref(route.query.store || null)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'updatedAt')
  const isSortDirDesc = ref(route.query.sortDesc ? (route.query.sortDesc === 'true') : true)
  const currentDataLength = ref(0)
  const toDeletedID = ref(null)
  const stockListData = ref([])
  const categoryFilter = ref([])
  if (Array.isArray(route.query.category)) {
    categoryFilter.value = route.query.category
  } else if (route.query.category) {
    categoryFilter.value = [route.query.category]
  }
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refStockItemsTable.value ? refStockItemsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalStock.value,
    }
  })

  const refetchData = () => {
    refStockItemsTable.value.refresh()
  }

  watch([searchQuery, categoryFilter, status, storeVal], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })
  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchStockLists = (ctx, callback) => {
    store
      .dispatch('app-stock-list/fetchStockLists', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        category: categoryFilter.value,
        status: status.value,
        store: storeVal.value,
      },
      { root: true })
      .then(response => {
        const { items, total } = response.data
        stockListData.value = items
        allStockCount.value = response.data.allCount
        outOfStockCount.value = response.data.outOfStockCount
        lowStockCount.value = response.data.lowStockCount
        currentDataLength.value = items.length
        callback(items)
        totalStock.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              category: categoryFilter.value,
              status: status.value,
              store: storeVal.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStockStatusVariant = stockStatus => {
    if (stockStatus === 'low stock') return 'warning'
    if (stockStatus === 'out of stock') return 'danger'
    return ''
  }

  return {
    fetchStockLists,
    tableColumns,
    perPage,
    currentPage,
    totalStock,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refStockItemsTable,
    refetchData,

    toDeletedID,
    stockListData,
    currentDataLength,
    allStockCount,
    outOfStockCount,
    lowStockCount,
    status,
    storeVal,
    resolveStockStatusVariant,

    // Extra Filters
    categoryFilter,
  }
}
